$(document).ready(function(){


	// $('.c-video').click(function() {
	// 	if( $(this).children(".c-video__video").get(0).paused ) {
	// 		$(this).children(".c-video__video").get(0).play();
	// 		$(this).children(".c-video__play").fadeOut();
    // 	} else {
	// 		$(this).children(".c-video__video").get(0).pause();
 	// 		$(this).children(".c-video__play").fadeIn();
    // 	}
	// });


})
