$(document).ready(function(){

	var menuToggle = $('.js-menu-toggle'),
		menu = $('.l-mobile-menu');

	menuToggle.on('click', function(e){

		e.preventDefault();

		if( !menu.hasClass('is-open') ) {
			menu.addClass('is-open');
			menuToggle.find('i').removeClass('fa-bars').addClass('fa-times');
		} else {
			menu.removeClass('is-open');
			menuToggle.find('i').removeClass('fa-times').addClass('fa-bars');
		}
	})


})
